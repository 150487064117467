/* eslint-disable @typescript-eslint/no-explicit-any */
import { MobileOs } from '@graphql/enums/mobile';

export const isMobile = () => /iphone|ipad|ipod|android/i.test(navigator.userAgent);
export const isStandalone = () => window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone || 'standalone' in window.navigator;
export const isIosOrAndroid = () => (isStandalone() && isMobile());
export const isIosOrAndroidButNotPwa = () => (!isStandalone() && isMobile());

export const getMobileOs = () => {
  if (/iPhone|iPad|ipod/i.test(navigator.userAgent)) return MobileOs.IOS;

  if (/Android/i.test(navigator.userAgent)) return MobileOs.ANDROID;

  return undefined;
};
