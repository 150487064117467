import { default as _91choice_93x3yBl2V5BoMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as indexyDAhk1H7FQMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexRskWwqSuO2Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/claims-en-opties/index.vue?macro=true";
import { default as _91hash_93AlhawCf0EVMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/einde-looptijd-lening/[hash].vue?macro=true";
import { default as indexWdloV65uUSMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/financieel-overzicht/financien/index.vue?macro=true";
import { default as indexAWq2sX2z6LMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/financieel-overzicht/index.vue?macro=true";
import { default as indexHqWH3SEBUDMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/financieel-overzicht/rente/index.vue?macro=true";
import { default as indexAZVQSYqqCaMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexjLkCq4USmpMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/hypotheek-overzicht/index.vue?macro=true";
import { default as indexb4h3eZhVPjMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/hypotheken/[id]/index.vue?macro=true";
import { default as indexVHLkyOkTIpMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/hypotheken/index.vue?macro=true";
import { default as indexeUuF7yDeV4Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/index.vue?macro=true";
import { default as index6JZwDJ8yF1Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/instellingen/index.vue?macro=true";
import { default as indexv5UEhwlvnzMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/investeringen/[id]/index.vue?macro=true";
import { default as indexXBgFr4ymn6Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/investeringen/index.vue?macro=true";
import { default as indexc1ac8NLRUpMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoord22t8QCiGBOMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetenSM0XEaEyAwMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexAhPGFThglEMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexmEBpmZpFtcMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexN2BqmdovPUMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexLJSNNMbjbiMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexyNkak6SBBUMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexFBoTCQozt6Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexPRh84yUBa3Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexPfiVwQuyhvMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexNR7uNYi1PeMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexfIGKFKJwzdMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexhqYfGnhdqdMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexR0WNawGI88Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexirEM1wzogzMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexXhfbT0PRzpMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexEJGFEwp6IqMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indexzkNsQqH0qJMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexrari3XhFwWMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexBIdwXmmQJYMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexPPuUwlvMCXMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorrX1TFVYG6iMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexsIEFaKJVqQMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcode1ql79V9ES0Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesiyOs5dXnpwMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_933uXYjegwH2Meta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93XbodJ6ejqzMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraagvVrP645n8dMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93CKLLyhRU5fMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_93Iy5idIy1PbMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93yTJEe6aSBAMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as indexLLu6hZlzYTMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91hash_939HBW8V6cBBMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/rentevoorstel/[hash].vue?macro=true";
import { default as _91fundKey_937ajgXUtF0zMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagDqd2AVi8UlMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexCODDGRmG7HMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_93l4MEMsQeorMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexTDa0b4YGkzMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93v8EtO4dW1HMeta } from "/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93x3yBl2V5BoMeta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93x3yBl2V5BoMeta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93x3yBl2V5BoMeta || {},
    alias: _91choice_93x3yBl2V5BoMeta?.alias || [],
    redirect: _91choice_93x3yBl2V5BoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: indexyDAhk1H7FQMeta?.name ?? "aanbod",
    path: indexyDAhk1H7FQMeta?.path ?? "/aanbod",
    meta: indexyDAhk1H7FQMeta || {},
    alias: indexyDAhk1H7FQMeta?.alias || [],
    redirect: indexyDAhk1H7FQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexRskWwqSuO2Meta?.name ?? "claims-en-opties",
    path: indexRskWwqSuO2Meta?.path ?? "/claims-en-opties",
    meta: indexRskWwqSuO2Meta || {},
    alias: indexRskWwqSuO2Meta?.alias || [],
    redirect: indexRskWwqSuO2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/claims-en-opties/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93AlhawCf0EVMeta?.name ?? "einde-looptijd-lening-hash",
    path: _91hash_93AlhawCf0EVMeta?.path ?? "/einde-looptijd-lening/:hash()",
    meta: _91hash_93AlhawCf0EVMeta || {},
    alias: _91hash_93AlhawCf0EVMeta?.alias || [],
    redirect: _91hash_93AlhawCf0EVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/einde-looptijd-lening/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexWdloV65uUSMeta?.name ?? "financieel-overzicht-financien",
    path: indexWdloV65uUSMeta?.path ?? "/financieel-overzicht/financien",
    meta: indexWdloV65uUSMeta || {},
    alias: indexWdloV65uUSMeta?.alias || [],
    redirect: indexWdloV65uUSMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/financieel-overzicht/financien/index.vue").then(m => m.default || m)
  },
  {
    name: indexAWq2sX2z6LMeta?.name ?? "financieel-overzicht",
    path: indexAWq2sX2z6LMeta?.path ?? "/financieel-overzicht",
    meta: indexAWq2sX2z6LMeta || {},
    alias: indexAWq2sX2z6LMeta?.alias || [],
    redirect: indexAWq2sX2z6LMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/financieel-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexHqWH3SEBUDMeta?.name ?? "financieel-overzicht-rente",
    path: indexHqWH3SEBUDMeta?.path ?? "/financieel-overzicht/rente",
    meta: indexHqWH3SEBUDMeta || {},
    alias: indexHqWH3SEBUDMeta?.alias || [],
    redirect: indexHqWH3SEBUDMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/financieel-overzicht/rente/index.vue").then(m => m.default || m)
  },
  {
    name: indexAZVQSYqqCaMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexAZVQSYqqCaMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexAZVQSYqqCaMeta || {},
    alias: indexAZVQSYqqCaMeta?.alias || [],
    redirect: indexAZVQSYqqCaMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjLkCq4USmpMeta?.name ?? "hypotheek-overzicht",
    path: indexjLkCq4USmpMeta?.path ?? "/hypotheek-overzicht",
    meta: indexjLkCq4USmpMeta || {},
    alias: indexjLkCq4USmpMeta?.alias || [],
    redirect: indexjLkCq4USmpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/hypotheek-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexb4h3eZhVPjMeta?.name ?? "hypotheken-id",
    path: indexb4h3eZhVPjMeta?.path ?? "/hypotheken/:id()",
    meta: indexb4h3eZhVPjMeta || {},
    alias: indexb4h3eZhVPjMeta?.alias || [],
    redirect: indexb4h3eZhVPjMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/hypotheken/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVHLkyOkTIpMeta?.name ?? "hypotheken",
    path: indexVHLkyOkTIpMeta?.path ?? "/hypotheken",
    meta: indexVHLkyOkTIpMeta || {},
    alias: indexVHLkyOkTIpMeta?.alias || [],
    redirect: indexVHLkyOkTIpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/hypotheken/index.vue").then(m => m.default || m)
  },
  {
    name: indexeUuF7yDeV4Meta?.name ?? "index",
    path: indexeUuF7yDeV4Meta?.path ?? "/",
    meta: indexeUuF7yDeV4Meta || {},
    alias: indexeUuF7yDeV4Meta?.alias || [],
    redirect: indexeUuF7yDeV4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6JZwDJ8yF1Meta?.name ?? "instellingen",
    path: index6JZwDJ8yF1Meta?.path ?? "/instellingen",
    meta: index6JZwDJ8yF1Meta || {},
    alias: index6JZwDJ8yF1Meta?.alias || [],
    redirect: index6JZwDJ8yF1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/instellingen/index.vue").then(m => m.default || m)
  },
  {
    name: indexv5UEhwlvnzMeta?.name ?? "investeringen-id",
    path: indexv5UEhwlvnzMeta?.path ?? "/investeringen/:id()",
    meta: indexv5UEhwlvnzMeta || {},
    alias: indexv5UEhwlvnzMeta?.alias || [],
    redirect: indexv5UEhwlvnzMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/investeringen/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXBgFr4ymn6Meta?.name ?? "investeringen",
    path: indexXBgFr4ymn6Meta?.path ?? "/investeringen",
    meta: indexXBgFr4ymn6Meta || {},
    alias: indexXBgFr4ymn6Meta?.alias || [],
    redirect: indexXBgFr4ymn6Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/investeringen/index.vue").then(m => m.default || m)
  },
  {
    name: indexc1ac8NLRUpMeta?.name ?? "login",
    path: indexc1ac8NLRUpMeta?.path ?? "/login",
    meta: indexc1ac8NLRUpMeta || {},
    alias: indexc1ac8NLRUpMeta?.alias || [],
    redirect: indexc1ac8NLRUpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoord22t8QCiGBOMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoord22t8QCiGBOMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoord22t8QCiGBOMeta || {},
    alias: reset_45wachtwoord22t8QCiGBOMeta?.alias || [],
    redirect: reset_45wachtwoord22t8QCiGBOMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenSM0XEaEyAwMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetenSM0XEaEyAwMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenSM0XEaEyAwMeta || {},
    alias: wachtwoord_45vergetenSM0XEaEyAwMeta?.alias || [],
    redirect: wachtwoord_45vergetenSM0XEaEyAwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexAhPGFThglEMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexAhPGFThglEMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexAhPGFThglEMeta || {},
    alias: indexAhPGFThglEMeta?.alias || [],
    redirect: indexAhPGFThglEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEBpmZpFtcMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexmEBpmZpFtcMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexmEBpmZpFtcMeta || {},
    alias: indexmEBpmZpFtcMeta?.alias || [],
    redirect: indexmEBpmZpFtcMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexN2BqmdovPUMeta?.name ?? "mijn-aanvragen",
    path: indexN2BqmdovPUMeta?.path ?? "/mijn-aanvragen",
    meta: indexN2BqmdovPUMeta || {},
    alias: indexN2BqmdovPUMeta?.alias || [],
    redirect: indexN2BqmdovPUMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexLJSNNMbjbiMeta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexLJSNNMbjbiMeta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexLJSNNMbjbiMeta || {},
    alias: indexLJSNNMbjbiMeta?.alias || [],
    redirect: indexLJSNNMbjbiMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexyNkak6SBBUMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexyNkak6SBBUMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexyNkak6SBBUMeta || {},
    alias: indexyNkak6SBBUMeta?.alias || [],
    redirect: indexyNkak6SBBUMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexFBoTCQozt6Meta?.name ?? "mijn-organisaties",
    path: indexFBoTCQozt6Meta?.path ?? "/mijn-organisaties",
    meta: indexFBoTCQozt6Meta || {},
    alias: indexFBoTCQozt6Meta?.alias || [],
    redirect: indexFBoTCQozt6Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexPRh84yUBa3Meta?.name ?? "mijn-partnerplan-token-choice",
    path: indexPRh84yUBa3Meta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexPRh84yUBa3Meta || {},
    alias: indexPRh84yUBa3Meta?.alias || [],
    redirect: indexPRh84yUBa3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPfiVwQuyhvMeta?.name ?? "mijn-partnerplan",
    path: indexPfiVwQuyhvMeta?.path ?? "/mijn-partnerplan",
    meta: indexPfiVwQuyhvMeta || {},
    alias: indexPfiVwQuyhvMeta?.alias || [],
    redirect: indexPfiVwQuyhvMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexNR7uNYi1PeMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexNR7uNYi1PeMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexNR7uNYi1PeMeta || {},
    alias: indexNR7uNYi1PeMeta?.alias || [],
    redirect: indexNR7uNYi1PeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexfIGKFKJwzdMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexfIGKFKJwzdMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexfIGKFKJwzdMeta || {},
    alias: indexfIGKFKJwzdMeta?.alias || [],
    redirect: indexfIGKFKJwzdMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexhqYfGnhdqdMeta?.name ?? "mijn-profiel",
    path: indexhqYfGnhdqdMeta?.path ?? "/mijn-profiel",
    meta: indexhqYfGnhdqdMeta || {},
    alias: indexhqYfGnhdqdMeta?.alias || [],
    redirect: indexhqYfGnhdqdMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexR0WNawGI88Meta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexR0WNawGI88Meta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexR0WNawGI88Meta || {},
    alias: indexR0WNawGI88Meta?.alias || [],
    redirect: indexR0WNawGI88Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexirEM1wzogzMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexirEM1wzogzMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexirEM1wzogzMeta || {},
    alias: indexirEM1wzogzMeta?.alias || [],
    redirect: indexirEM1wzogzMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexXhfbT0PRzpMeta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexXhfbT0PRzpMeta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexXhfbT0PRzpMeta || {},
    alias: indexXhfbT0PRzpMeta?.alias || [],
    redirect: indexXhfbT0PRzpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexEJGFEwp6IqMeta?.name ?? "mogelijk-fonds-id",
    path: indexEJGFEwp6IqMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexEJGFEwp6IqMeta || {},
    alias: indexEJGFEwp6IqMeta?.alias || [],
    redirect: indexEJGFEwp6IqMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzkNsQqH0qJMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indexzkNsQqH0qJMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indexzkNsQqH0qJMeta || {},
    alias: indexzkNsQqH0qJMeta?.alias || [],
    redirect: indexzkNsQqH0qJMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexrari3XhFwWMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexrari3XhFwWMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexrari3XhFwWMeta || {},
    alias: indexrari3XhFwWMeta?.alias || [],
    redirect: indexrari3XhFwWMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexBIdwXmmQJYMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexBIdwXmmQJYMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexBIdwXmmQJYMeta || {},
    alias: indexBIdwXmmQJYMeta?.alias || [],
    redirect: indexBIdwXmmQJYMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexPPuUwlvMCXMeta?.name ?? "mogelijk-fonds",
    path: indexPPuUwlvMCXMeta?.path ?? "/mogelijk-fonds",
    meta: indexPPuUwlvMCXMeta || {},
    alias: indexPPuUwlvMCXMeta?.alias || [],
    redirect: indexPPuUwlvMCXMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorrX1TFVYG6iMeta?.name ?? "ockto-hash-error",
    path: errorrX1TFVYG6iMeta?.path ?? "/ockto/:hash()/error",
    meta: errorrX1TFVYG6iMeta || {},
    alias: errorrX1TFVYG6iMeta?.alias || [],
    redirect: errorrX1TFVYG6iMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexsIEFaKJVqQMeta?.name ?? "ockto-hash",
    path: indexsIEFaKJVqQMeta?.path ?? "/ockto/:hash()",
    meta: indexsIEFaKJVqQMeta || {},
    alias: indexsIEFaKJVqQMeta?.alias || [],
    redirect: indexsIEFaKJVqQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcode1ql79V9ES0Meta?.name ?? "ockto-hash-qrcode",
    path: qrcode1ql79V9ES0Meta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcode1ql79V9ES0Meta || {},
    alias: qrcode1ql79V9ES0Meta?.alias || [],
    redirect: qrcode1ql79V9ES0Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesiyOs5dXnpwMeta?.name ?? "ockto-hash-succes",
    path: succesiyOs5dXnpwMeta?.path ?? "/ockto/:hash()/succes",
    meta: succesiyOs5dXnpwMeta || {},
    alias: succesiyOs5dXnpwMeta?.alias || [],
    redirect: succesiyOs5dXnpwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_933uXYjegwH2Meta?.name ?? "onboarding-signing_id-token",
    path: _91token_933uXYjegwH2Meta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_933uXYjegwH2Meta || {},
    alias: _91token_933uXYjegwH2Meta?.alias || [],
    redirect: _91token_933uXYjegwH2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XbodJ6ejqzMeta?.name ?? "onboarding-slug",
    path: _91slug_93XbodJ6ejqzMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93XbodJ6ejqzMeta || {},
    alias: _91slug_93XbodJ6ejqzMeta?.alias || [],
    redirect: _91slug_93XbodJ6ejqzMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraagvVrP645n8dMeta?.name ?? "onboarding-aanvraag",
    path: aanvraagvVrP645n8dMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraagvVrP645n8dMeta || {},
    alias: aanvraagvVrP645n8dMeta?.alias || [],
    redirect: aanvraagvVrP645n8dMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93CKLLyhRU5fMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93CKLLyhRU5fMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93CKLLyhRU5fMeta || {},
    alias: _91experience_93CKLLyhRU5fMeta?.alias || [],
    redirect: _91experience_93CKLLyhRU5fMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Iy5idIy1PbMeta?.name ?? "onfido-token",
    path: _91token_93Iy5idIy1PbMeta?.path ?? "/onfido/:token()",
    meta: _91token_93Iy5idIy1PbMeta || {},
    alias: _91token_93Iy5idIy1PbMeta?.alias || [],
    redirect: _91token_93Iy5idIy1PbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93yTJEe6aSBAMeta?.name ?? "passeren-hash",
    path: _91hash_93yTJEe6aSBAMeta?.path ?? "/passeren/:hash()",
    meta: _91hash_93yTJEe6aSBAMeta || {},
    alias: _91hash_93yTJEe6aSBAMeta?.alias || [],
    redirect: _91hash_93yTJEe6aSBAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexLLu6hZlzYTMeta?.name ?? "privacy-verklaring",
    path: indexLLu6hZlzYTMeta?.path ?? "/privacy-verklaring",
    meta: indexLLu6hZlzYTMeta || {},
    alias: indexLLu6hZlzYTMeta?.alias || [],
    redirect: indexLLu6hZlzYTMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_939HBW8V6cBBMeta?.name ?? "rentevoorstel-hash",
    path: _91hash_939HBW8V6cBBMeta?.path ?? "/rentevoorstel/:hash()",
    meta: _91hash_939HBW8V6cBBMeta || {},
    alias: _91hash_939HBW8V6cBBMeta?.alias || [],
    redirect: _91hash_939HBW8V6cBBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/rentevoorstel/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_937ajgXUtF0zMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_937ajgXUtF0zMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_937ajgXUtF0zMeta || {},
    alias: _91fundKey_937ajgXUtF0zMeta?.alias || [],
    redirect: _91fundKey_937ajgXUtF0zMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagDqd2AVi8UlMeta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagDqd2AVi8UlMeta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagDqd2AVi8UlMeta || {},
    alias: aanvraagDqd2AVi8UlMeta?.alias || [],
    redirect: aanvraagDqd2AVi8UlMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexCODDGRmG7HMeta?.name ?? "sso-exchangeToken-onboarding",
    path: indexCODDGRmG7HMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexCODDGRmG7HMeta || {},
    alias: indexCODDGRmG7HMeta?.alias || [],
    redirect: indexCODDGRmG7HMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93l4MEMsQeorMeta?.name ?? "sso-token-id",
    path: _91id_93l4MEMsQeorMeta?.path ?? "/sso/:token()/:id()",
    meta: _91id_93l4MEMsQeorMeta || {},
    alias: _91id_93l4MEMsQeorMeta?.alias || [],
    redirect: _91id_93l4MEMsQeorMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTDa0b4YGkzMeta?.name ?? "sso-token",
    path: indexTDa0b4YGkzMeta?.path ?? "/sso/:token()",
    meta: indexTDa0b4YGkzMeta || {},
    alias: indexTDa0b4YGkzMeta?.alias || [],
    redirect: indexTDa0b4YGkzMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93v8EtO4dW1HMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93v8EtO4dW1HMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93v8EtO4dW1HMeta || {},
    alias: _91hash_93v8EtO4dW1HMeta?.alias || [],
    redirect: _91hash_93v8EtO4dW1HMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250331130707/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]